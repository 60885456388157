import { Button, Card, Col, Row, Form, Typography, Select, Spin, Input, DatePicker, Switch } from "antd";
import { RightOutlined, LeftOutlined, PlusSquareOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import ls from "localstorage-ttl";
import "../../../Styles/TwoTables.scss";
import Tecdoc from "../../../Utils/Tecdoc";
import ClientModal from "../../Vente/Clients/ClientModal";
import Article from "../../../Models/Article";
import { toAddOrUpdateArticle } from "../../Gestions/Api";
import { errorMsg, successMsg, toFormatArticleClassifications } from "../../../Utils/Utils";
import ArticleModal from "../../Gestions/ArticleModal";
import history from "../../../Utils/History";
import { connect } from "react-redux";
import { addItem, clearItems, setProvider, setItemsState, setAchat, setFODEC } from "../../../Actions/ItemsSelectionAtions";
import ItemsSelection from "../../../Components/ItemsSelection";
import { toCreateBR, toGetBonReception, toGetProviderList } from "../APIs";
import ProviderModal from "../Fournisseurs/ProviderModal";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
import localization from "moment/locale/fr";
import { DataExistsException } from "../../../DataExitsException";

moment.updateLocale("fr", localization);
const { Option } = Select;

const BonReception = ({ bonreception, AppStore, ...props }) => {
  const { id } = useParams();
  const [provider, setProvider] = useState(null);
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  const [activeTable, setActiveTable] = useState(1);
  const [reference, setReference] = useState("");
  const [providers, setProviders] = useState(null);

  const [marge, setMarge] = useState(-1);
  const [checked, setChecked] = useState(false);
  const [savingState, setSavingState] = useState({
    saving: false,
    print: false,
  });
  const [form] = Form.useForm();

  const [state, setState] = useState({
    createModalVisible: false,
    providerForm: {
      id: -1,
      s__email: "",
      s__fax: "",
      s__matricule: "",
      s__nom: "",
      s__nomInCharge: "",
      s__phone: "",
      s__phoneInCharge: "",
      s__register: "",
    },
    readonly: false,
  });

  useEffect(() => {
    props.clearItems();

    AppStore.setFirst(0);
    AppStore.setSecond(2);

    if (null != id) {
      toGetBonReception(id).then((order) => {
        const orderToUpdate = {
          id: order.id,
          total: order.d__total,
          discount: order.d__discount,
          s__blProvider: order.s__blProvider,
          s__blDate: order.s__blDate,
          items: [],
          provider: order.provider,
        };
        form.setFieldsValue({
          blFournisseur: order.s__blProvider,
          blDate: moment(order.s__blDate),
        });
        for (let item of order.items) {
          orderToUpdate.items.push({
            ...item,

            ref: item.article.s__reference,
            titre: item.article.s__secondTitle,
            quantity: item.d__quantity,
            marge: item.d__marge,
            uPriceHt: item.d__unitPriceHT,
            uPriceTTC: item.d__unitPriceTTC,
            total: item.d__total,
            discount: item.d__discount,
            prix_achat: item.article.l__prixAchatTTC,
            prix_unitaire: item.article.l__prixVenteHT,
            prix_unitaireTTC: item.article.l__prixVenteTTC,
            prix_unitaire_gros: item.article.l__prixVenteGrosHT,
            prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
            tva: item.article.tva.title,
          });
        }

        props.setBRToUpdate(orderToUpdate);
      });
    }
    props.setAchat(true);
    if (null != ls.get("providers")) {
      setProviders(ls.get("providers"));
    }
    toGetProviderList().then((data) => setProviders(data));
  }, [id]);

  const saveProvider = (provider) => {
    if (state.readonly) return;

    setProviders(providers.concat(provider));
    chooseProvider(provider.s__nom, { data: provider });
    setState({ createModalVisible: false, readonly: false, provider: null });
  };

  const onSelectChange = (e) => {
    var p = providers.filter((a) => a.s__nom == document.getElementById("input").value);
    if (p.length > 0) {
      props.setProvider(p[0]);
      setProvider(p);
    } else {
      props.setProvider(null);
      setProvider(null);
    }
  };

  const saveBR = async (active) => {
    form
      .validateFields()
      .then((res) => {
        var toedit = false;
        if (id) {
          toedit = true;
        }
        if (bonreception.provider == null) {
          errorMsg("Selectionnez un fournisseur");
          return;
        }

        setSavingState({ saving: true });
        toCreateBR(
          bonreception.provider,
          bonreception.items,
          bonreception.discount,
          bonreception.total,
          form.getFieldValue("blFournisseur"),
          form.getFieldValue("blDate"),
          form.getFieldValue("moyenne"),
          checked,
          toedit,
          id,
          active,
          form.getFieldValue("fodec")
        )
          .then(() => history.push("/achat/reception/list"))
          .catch(() => {
            setSavingState({ saving: false });
          });
      })
      .catch((errorInfo) => {
        errorMsg("Merci d'ajouter les infos manquantes");
      });
  };

  const switchTable = () => {
    activeTable === 1 ? setActiveTable(2) : setActiveTable(1);
  };

  const addFromTecdoc = async (article) => {
    var a = new Article();
    a.titre = article.fullName;
    a.reference = article.ref;
    a.marque = article.brand.name;
    a.type = "Adaptable";
    a.categorie = article.fullName;
    a.data = toFormatArticleClassifications(article);
    a.referenceTecdoc = article.ref;
    a.fromTecdoc = true;

    setArticleDetails(a);

    setCreateModalVisible(true);
  };

  const saveArticle = async (id, article) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article);
      if (id === -1) {
        setReference(newArticle.s__reference);
        successMsg("Article créé avec succés");
      }
      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }
  };

  const chooseProvider = (providerName, option) => {
    if (providerName === "0")
      setState({
        readonly: false,
        createModalVisible: true,
        providerForm: {
          id: -1,
          s__email: "",
          s__fax: "",
          s__matricule: "",
          s__nom: "",
          s__nomInCharge: "",
          s__phone: "",
          s__phoneInCharge: "",
          s__register: "",
        },
      });
    else {
      props.setProvider(option.data);
      setProvider(option.data);
    }
  };

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };
  const openReadOnlyModal = () =>
    setState({
      providerForm: provider,
      createModalVisible: true,
      readonly: true,
    });

  const updateMarge = (value) => {
    setMarge(value);
  };

  const rightColHeader = (
    <Row justify="space-between">
      <Col md={12}>
        <Typography.Title style={{ marginLeft: "10px" }} level={5}>
          Bon de réception
        </Typography.Title>
      </Col>
      <Col md={12} style={{ textAlign: "right" }}>
        <Row gutter={8}>
          <Col span={8}>
            <Typography.Title level={4}>Fournisseur :</Typography.Title>
          </Col>
          <Col span={4}>
            <Button icon={<InfoCircleTwoTone />} onClick={() => openReadOnlyModal()} />
          </Col>
          <Col span={12} style={{ textAlign: "left" }}>
            {/* <Select
              showSearch
              value={
                bonreception.provider != null
                  ? bonreception.provider.s__nom
                  : null
              }
              onSelect={chooseProvider}
              style={{ width: "100%" }}
              placeholder="Cherecher un fournisseur"
              filterOption={(input, option) =>
                option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="0">
                <PlusSquareOutlined /> Nouveau fournisseur
              </Option>
              {providers &&
                providers.map((provider) => (
                  <Option
                    key={provider.id}
                    value={provider.s__nom}
                    data={provider}
                  >
                    {provider.s__nom}
                  </Option>
                ))}
            </Select> */}
            <input list="ice-cream-flavors" id="input" name="ice-cream-choice" onChange={(e) => onSelectChange(e)} />

            <datalist id="ice-cream-flavors">
              {providers &&
                providers.map((provider) => (
                  <option key={provider.id} value={provider.s__nom} data={provider}>
                    {provider.s__nom}
                  </option>
                ))}
            </datalist>
          </Col>
        </Row>
      </Col>

      <Form form={form}>
        <Row style={{ marginTop: "1vh" }}>
          <Col span={6}>
            <Form.Item label="BL Fournisseur" rules={[{ required: true, message: "Champ obligatoire" }]} name="blFournisseur">
              <Input />
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Form.Item label="BL Date" rules={[{ required: true, message: "Champ obligatoire" }]} name="blDate">
              <DatePicker />
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Form.Item label="Moyenne" name="moyenne">
              <Switch></Switch>
            </Form.Item>
          </Col>
          <Col span={6} style={{ textAlign: "right" }}>
            <Form.Item label="FODEC" name="fodec">
              <Switch onChange={(e) => props.setFODEC(e)}></Switch>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {state.clientForm && (
        <ClientModal
          clientForm={state.clientForm}
          visible={state.createModalVisible}
          onCancel={() =>
            setState({
              createModalVisible: false,
              clientForm: null,
              readonly: false,
            })
          }
          onClientSaved={null}
          readonly={state.readonly}
        />
      )}
    </Row>
  );

  return (
    <>
      <Row style={{ height: "100%" }} className="two-tables-row">
        <Col lg={12} md={24} className={"articles-list"}>
          <Card>
            <Tecdoc addFromTecdoc={addFromTecdoc} displayTitle={true} addItem={props.addItem} reference={reference} achat={true} />
          </Card>
        </Col>
        <Col lg={12} md={24} className={"selection-list" + (activeTable === 2 ? " active" : "")}>
          <Card title={rightColHeader} style={{ minHeight: "100%" }}>
            <ItemsSelection displayMarge={true} getMarge={updateMarge} />
            {bonreception.items && bonreception.items.length > 0 && (
              <div className="selection-footer">
                <Row justify="end" gutter={8}>
                  <Col xl={6} lg={6}>
                    <Button onClick={() => saveBR(false)} block type="dashed" style={{ marginRight: "10px" }} disabled={savingState.saving}>
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print ? "text-top" : "baseline",
                        }}
                      >
                        Enregister brouillon
                      </span>
                      {savingState.saving && !savingState.print && <Spin size="small" style={{ marginLeft: 10 }} />}
                    </Button>
                  </Col>
                  <Col xl={6} lg={6}>
                    <Button onClick={() => saveBR(true)} block type={"primary"} style={{ marginRight: "10px" }} disabled={savingState.saving}>
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print ? "text-top" : "baseline",
                        }}
                      >
                        Enregister
                      </span>
                      {savingState.saving && !savingState.print && <Spin size="small" style={{ marginLeft: 10 }} />}
                    </Button>
                  </Col>
                  <Col xl={6} lg={6}>
                    <Button block onClick={() => saveBR(true)} disabled={savingState.saving} style={{ marginRight: 10 }}>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: savingState.print ? "text-top" : "baseline",
                        }}
                      >
                        Enregistrer et Imprimer{" "}
                      </span>
                      {savingState.print && <Spin size="small" style={{ marginLeft: 10 }} />}
                    </Button>
                  </Col>
                  <Col xl={6} lg={6}>
                    <Button block danger onClick={props.clearItems}>
                      <Link to="/achat/reception">Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        </Col>
      </Row>

      <Button className={"table-switch" + (activeTable === 2 ? " left" : "")} onClick={switchTable}>
        {activeTable === 1 ? <RightOutlined /> : <LeftOutlined />}
      </Button>
      {state.providerForm && (
        <ProviderModal
          providerForm={state.providerForm}
          visible={state.createModalVisible}
          exitCreateModal={() =>
            setState({
              createModalVisible: false,
              providerForm: null,
              readonly: false,
            })
          }
          onProviderSaved={saveProvider}
          readonly={state.readonly}
        />
      )}

      <ArticleModal saveArticle={saveArticle} details={articleDetails} createModalVisible={createModalVisible} closeCreateModal={exitCreateModal} />
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    bonreception: itemSelectionState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBRToUpdate: (PurshaseOrder) => dispatch(setItemsState(PurshaseOrder)),
    addItem: (item) => dispatch(addItem(item)),
    setProvider: (provider) => dispatch(setProvider(provider)),
    setAchat: (value) => dispatch(setAchat(value)),
    setFODEC: (value) => dispatch(setFODEC(value)),
    clearItems: () => dispatch(clearItems()),
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BonReception);
