import { Button, Card, Col, Row, Typography, Select, Input, Modal, Alert, Spin } from "antd";
import { RightOutlined, LeftOutlined, PlusSquareOutlined, InfoCircleTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

import "../../../Styles/TwoTables.scss";
import Tecdoc from "../../../Utils/Tecdoc";
import { toGetClientsList } from "../APIs";
import Client from "../../../Models/Client";
import ClientModal from "../Clients/ClientModal";
import Article from "../../../Models/Article";
import { toAddOrUpdateArticle } from "../../Gestions/Api";
import { errorMsg, successMsg, toFormatArticleClassifications, verifyAdminCode } from "../../../Utils/Utils";
import ArticleModal from "../../Gestions/ArticleModal";
import { toCreateQuote, toGetQuote, toPrintQuote, toEditQuote } from "../APIs";
import history from "../../../Utils/History";
import { connect } from "react-redux";
import ItemsSelection from "../../../Components/ItemsSelection";
import { addItem, clearItems, setClient, setAchat, setItemsState } from "../../../Actions/ItemsSelectionAtions";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import GaugeChart from "react-gauge-chart";
import ls from "localstorage-ttl";
import cookie from "react-cookies";
import { DataExistsException } from "../../../DataExitsException";
import ClientDetail from "../Clients/ClientDetail";

const { Option } = Select;

const Quote = ({ AppStore, quote, ...props }) => {
  const updatingQuote = useRouteMatch("/vente/devis/modifier/:id");
  const { id } = useParams();
  const [createModalVisible, setCreateModalVisible] = useState(false);
  const [marge, setMarge] = useState(-1);
  const [note, setNote] = useState("");
  const [articleDetails, setArticleDetails] = useState({
    title: "",
    reference: "",
    marque: "",
    type: "Origine",
    categorie: "",
  });

  const [state, setState] = useState({
    createModalVisible: false,
    clientForm: new Client(),
    readonly: false,
  });
  const [client, setClient] = useState(null);

  const [activeTable, setActiveTable] = useState(1);
  const [reference, setReference] = useState("");
  const [clients, setClients] = useState(null);
  const [clientModalState, setClientModalState] = useState({
    visible: false,
    client: new Client(),
  });
  const [adminCode, setAdminCode] = useState("");
  const [isAdminModalVisible, setAdminModalVisible] = useState(false);
  const [wrongAdminCode, setWrongAdminCode] = useState(false);
  const [percentage, setPercentage] = useState(0);

  const [savingState, setSavingState] = useState({
    saving: false,
    print: false,
  });

  useEffect(() => {
    props.clearItems();
    AppStore.setFirst(1);
    AppStore.setSecond(7);
    props.setAchat(false);
    if (null != ls.get("clients")) {
      setClients(ls.get("clients"));

      if (id == null) {
        setClient(null);
        props.setClient(null);
      }
    }
    toGetClientsList().then((data) => setClients(data));

    if (updatingQuote && quote.items.length === 0) {
      toGetQuote(id).then((res) => {
        const quoteToUpdate = {
          total: res.d__total,
          discount: res.d__discount,
          items: [],
          client: res.client,
        };
        setNote(res.s__note);
        for (let item of res.quote__items) {
          quoteToUpdate.items.push({
            ...item,
            ref: item.article.s__reference,
            titre: item.article.s__secondTitle,
            quantity: item.d__quantity,
            uPriceHt: item.d__unitPriceHT,
            uPriceTTC: item.d__unitPriceTTC,
            total: item.d__total,
            discount: item.d__discount,
            prix_achat: item.article.l__prixAchatTTC,
            prix_unitaire: item.article.l__prixVenteHT,
            prix_unitaireTTC: item.article.l__prixVenteTTC,
            prix_unitaire_gros: item.article.l__prixVenteGrosHT,
            prix_unitaire_grosTTC: item.article.l__prixVenteGrosTTC,
            tva: item.article.tva.title,
          });
        }
        props.setQuoteToUpdate(quoteToUpdate);
      });
    }
  }, []);

  const saveClient = (client) => {
    setClients(clients.concat(client));
    chooseClient(client.s__name, { data: client });
    setClientModalState({ visible: false, client: null });
  };

  const saveQuote = async (print = false) => {
    if (quote.client == null) {
      errorMsg("Selectionnez un client");
      return;
    }

    if (marge < percentage) {
      setAdminModalVisible(true);
      return;
    }

    if (isAdminModalVisible) {
      try {
        await verifyAdminCode(adminCode);
      } catch (e) {
        setWrongAdminCode(true);
        return;
      }
    }
    setSavingState({ saving: true, print });
    try {
      let res;
      if (updatingQuote) {
        res = await toEditQuote(parseInt(id), quote.client, quote.items, note, quote.discount);
      } else {
        res = await toCreateQuote(quote.client, quote.items, note, quote.discount);
      }
      const { data } = res;
      setSavingState({ saving: false, print: false });
      setAdminCode("");
      setAdminModalVisible(false);
      setWrongAdminCode(false);
      props.clearItems();
      history.push("/vente/devis/list");
      if (print) {
        let { quote: newQuote } = data.success;
        newQuote = JSON.parse(newQuote);
        toPrintQuote(newQuote.id, true, true);
      }
    } catch (e) {
      console.log(e);
      errorMsg("Une erreur est survenue");
      setSavingState({ saving: false, print: false });
    }
  };

  const switchTable = () => {
    activeTable === 1 ? setActiveTable(2) : setActiveTable(1);
  };

  const addFromTecdoc = async (article) => {
    var a = new Article();
    a.titre = article.fullName;
    a.reference = article.ref;
    a.marque = article.brand.name;
    a.type = "Adaptable";
    a.categorie = article.fullName;
    a.data = toFormatArticleClassifications(article);
    a.referenceTecdoc = article.ref;
    a.fromTecdoc = true;

    setArticleDetails(a);

    setCreateModalVisible(true);
  };

  const saveArticle = async (id, article) => {
    try {
      var newArticle = await toAddOrUpdateArticle(id, article);
      if (id === -1) {
        setReference(newArticle.s__reference);
        successMsg("Article créé avec succés");
      }
      setCreateModalVisible(false);
    } catch (e) {
      if (e instanceof DataExistsException) {
        errorMsg("L'article existe déja");
      } else {
        errorMsg("Une erreur est survenue");
      }
    }
  };

  const chooseClient = (clientName, option) => {
    if (clientName === "0") setClientModalState({ visible: true, client: new Client() });
    else {
      props.setClient(option.data);
      setClient(option.data);
    }
  };

  const exitCreateModal = () => {
    setCreateModalVisible(false);
  };

  const openReadOnlyModal = () => setState({ clientForm: client, createModalVisible: true, readonly: true });

  const closeClientModal = () => {
    setClientModalState({ createModalVisible: false, clientForm: null });
  };

  const updateMarge = (value) => {
    setMarge(value);
  };

  const rightColHeader = (
    <>
      <Row justify="space-between">
        <Col md={10}>
          <Row gutter={8}>
            <Col span={12}>
              <Typography.Title style={{ marginLeft: "10px" }} level={5}>
                Devis
              </Typography.Title>
            </Col>
            <Col span={12}>
              <div style={{}}>
                {marge != -1 && (
                  <>
                    <span style={{ marginLeft: "10%" }}>{marge + " %"}</span>
                    <br />
                    <GaugeChart
                      id="gauge-chart5"
                      nrOfLevels={100}
                      arcsLength={[0.1, 0.25, 0.65]}
                      colors={["#EA4228", "#F5CD19", "#5BE12C"]}
                      percent={marge / 100}
                      textColor={"transparent"}
                      arcPadding={0.01}
                      style={{ height: "50%", width: "50%" }}
                    ></GaugeChart>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={14} style={{ textAlign: "right" }}>
          <Row gutter={8}>
            <Col span={8}>
              <Typography.Title level={4}>Client :</Typography.Title>
            </Col>
            <Col span={4}>
              <Button type="link" icon={<img src="/images/clientVert.svg" />} onClick={() => openReadOnlyModal()} />
            </Col>
            <Col span={12} style={{ textAlign: "left" }}>
              <Select
                showSearch
                value={quote.client != null ? quote.client.s__name : null}
                onSelect={chooseClient}
                style={{ width: "100%" }}
                placeholder="Cherecher un client"
                filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {cookie.load("role") == "Super Admin" && (
                  <Option value="0">
                    <PlusSquareOutlined /> Nouveau client
                  </Option>
                )}

                {clients &&
                  clients.map((client) => (
                    <Option
                      style={{
                        backgroundColor: client.b__billPerMonth ? "#69c0ff" : client.b__billPerWeek ? "#846eb1" : "white",
                      }}
                      key={client.s__code}
                      value={client.s__code + " " + client.s__name}
                      data={client}
                    >
                      {client.s__name}
                    </Option>
                  ))}
              </Select>
            </Col>
          </Row>
        </Col>
        {state.clientForm && (
          // <ClientModal
          //   clientForm={state.clientForm}
          //   visible={state.createModalVisible}
          //   onCancel={() =>
          //     setState({
          //       createModalVisible: false,
          //       clientForm: null,
          //       readonly: false,
          //     })
          //   }
          //   onClientSaved={null}
          //   readonly={state.readonly}
          // />
          <ClientDetail
            client={state.clientForm}
            visible={state.createModalVisible}
            dismiss={() =>
              setState({
                createModalVisible: false,
                clientForm: null,
                readonly: false,
              })
            }
          />
        )}
      </Row>
      <Row justify={"end"}>
        <Col span={12}>
          Note: <Input name="s__note" style={{ width: "90%" }} value={note} onChange={(e) => setNote(e.target.value)} />
        </Col>
      </Row>
    </>
  );

  const ModalClient = (
    <Modal footer={null} visible={null == quote.client}>
      <Row gutter={8} style={{ marginTop: "4vh" }}>
        <Col span={8}>
          <Typography.Title level={4}>Client :</Typography.Title>
        </Col>
        <Col span={2}>
          <Button type={"link"} icon={<img src="/images/clientVert.svg" />} onClick={() => openReadOnlyModal()} />
        </Col>
        <Col span={14} style={{ textAlign: "left" }}>
          <Select
            showSearch
            value={quote.client != null ? quote.client.s__name : null}
            onSelect={chooseClient}
            style={{ width: "100%" }}
            placeholder="Cherecher un client"
            filterOption={(input, option) => option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option value="0">
              <PlusSquareOutlined /> Nouveau client
            </Option>

            {clients &&
              clients
                .filter((c) => !c.b__locked)
                .map((client) => (
                  <Option
                    style={{
                      backgroundColor:
                        client.d__threshold > 0 && client.d__threshold < client.unpaied
                          ? "red"
                          : client.b__billPerMonth
                          ? "#69c0ff"
                          : client.b__billPerWeek
                          ? "#846eb1"
                          : "white",
                    }}
                    key={client.s__code}
                    value={client.s__code + " " + client.s__name}
                    data={client}
                  >
                    {client.s__name}
                  </Option>
                ))}
          </Select>
        </Col>
      </Row>
    </Modal>
  );
  return (
    <>
      {ModalClient}

      <Row style={{ height: "100%" }} className="two-tables-row">
        <Col lg={12} md={24} xs={24} className={"articles-list"}>
          <Card>
            <Tecdoc addFromTecdoc={addFromTecdoc} displayTitle={true} addItem={props.addItem} reference={reference} achat={false} />
          </Card>
        </Col>
        <Col lg={12} md={24} xs={24} className={"selection-list" + (activeTable === 2 ? " active" : "")}>
          <Card title={rightColHeader} style={{ minHeight: "100%" }}>
            <ItemsSelection getMarge={updateMarge} />
            {quote.items && quote.items.length > 0 && (
              <div className="selection-footer">
                <Row justify="end" gutter={8}>
                  <Col xl={6} lg={8}>
                    <Button onClick={() => saveQuote()} block type={"primary"} style={{ marginRight: "10px" }} disabled={savingState.saving}>
                      <span
                        style={{
                          marginRight: 10,
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: !savingState.print ? "text-top" : "baseline",
                        }}
                      >
                        Enregister
                      </span>
                      {savingState.saving && !savingState.print && <Spin size="small" style={{ marginLeft: 10 }} />}
                    </Button>
                  </Col>
                  <Col xl={6} lg={8}>
                    <Button block onClick={() => saveQuote(true)} disabled={savingState.saving} style={{ marginRight: 10 }}>
                      <span
                        style={{
                          whiteSpace: "nowrap",
                          maxWidth: "calc(100% - 20px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          verticalAlign: savingState.print ? "text-top" : "baseline",
                        }}
                      >
                        Enregistrer et Imprimer{" "}
                      </span>
                      {savingState.print && <Spin size="small" style={{ marginLeft: 10 }} />}
                    </Button>
                  </Col>
                  <Col xl={6} lg={8}>
                    <Button block danger onClick={props.clearItems}>
                      <Link to="/vente/devis">Annuler</Link>
                    </Button>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        </Col>
      </Row>

      <Button className={"table-switch" + (activeTable === 2 ? " left" : "")} onClick={switchTable}>
        {activeTable === 1 ? <RightOutlined /> : <LeftOutlined />}
      </Button>
      {clientModalState.client && (
        <ClientModal visible={clientModalState.visible} clientForm={clientModalState.client} onClientSaved={saveClient} onCancel={closeClientModal} />
      )}

      <ArticleModal saveArticle={saveArticle} details={articleDetails} createModalVisible={createModalVisible} closeCreateModal={exitCreateModal} />
      <Modal title="Operation non autorisée" visible={isAdminModalVisible} onOk={saveQuote} onCancel={() => setAdminModalVisible(false)}>
        <p>Cette opération n'est pas permise car la marge est inférieur à {percentage} %</p>
        {wrongAdminCode && <Alert message="Code admin incorrect" type="error" style={{ marginBottom: 10 }} />}
        <Input placeholder="Code admin" onChange={(e) => setAdminCode(e.target.value)} />
      </Modal>
    </>
  );
};

const mapStateToProps = ({ itemSelectionState }) => {
  return {
    quote: itemSelectionState,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addItem: (item) => dispatch(addItem(item)),
    setClient: (client) => dispatch(setClient(client)),
    setAchat: (value) => dispatch(setAchat(value)),
    clearItems: () => dispatch(clearItems()),
    setQuoteToUpdate: (quote) => dispatch(setItemsState(quote)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Quote);
