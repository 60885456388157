import { Button, Divider, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { toGetBonReception } from "../APIs";
import { printEtat, toAddOrUpdateEmplacement, toChangeEmplacementApi, toGetEmplacementList } from "../../Gestions/Api";
import { successMsg } from "../../../Utils/Utils";
import { PlusOutlined } from "@ant-design/icons";

const ChangeEmplacement = ({ id, onClose }) => {
  const [bon, setBon] = useState(null);
  const [emplacements, setEmplacements] = useState([]);
  const [articleEmplacement, setArticleEmplacement] = useState([]);
  const [emplacementToAdd, setEmplacementToAdd] = useState("");
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    if (-1 != id) {
      setArticleEmplacement([]);
      toGetBonReception(id).then((res) => {
        setBon(res);
        var x = [];
        res.items.map((el) => {
          x.push({ articleId: el.article.id, emplacement: el.article.emplacement ? el.article.emplacement.title : "" });
        });
        setArticleEmplacement(x);
      });
    }
  }, [id]);

  useEffect(() => {
    toGetEmplacement();
  }, []);

  const toGetEmplacement = () => {
    toGetEmplacementList().then((res) => {
      setEmplacements(res);
    });
  };

  const onChangeEmplacement = (id, newEmplacement) => {
    const list = [...articleEmplacement];
    list.filter((el) => el.articleId == id)[0].emplacement = newEmplacement.target.value;
    setArticleEmplacement(list);
  };

  const validateChange = () => {
    toChangeEmplacementApi(articleEmplacement).then((res) => {
      successMsg("Emplacement modifiés avec succées");
    });
  };

  const addItemEmplacement = () => {
    toAddOrUpdateEmplacement(emplacementToAdd, -1).then((emplacement) => {
      if (null != emplacement) {
        successMsg("Emplacement ajoutée avec succées!");
        setEmplacements([...emplacements, emplacement]);
        setEmplacementToAdd("");
      }
    });
  };

  return (
    <Modal
      onOk={() => validateChange()}
      className="smtv-modal modal-large"
      visible={bon !== null}
      onCancel={() => {
        setBon(null);
        onClose();
      }}
      title="Modifier Emplacement"
    >
      <h6 style={{ textAlign: "center" }}>Bon de réception {bon?.s__ref}</h6>
      <Button type="primary" onClick={() => printEtat(id)}>
        Imprimer l'état
      </Button>
      <div>
        <h7 style={{ textAlign: "center", marginTop: "40px", fontWeight: "bold" }}>Articles</h7>
      </div>
      <table className="ant-table" style={{ width: "100%" }}>
        <thead className="ant-table-thead">
          <tr>
            <th className="ant-table-cell" style={{ width: "8%" }}>
              Ref
            </th>
            <th className="ant-table-cell">Désignation</th>
            <th className="ant-table-cell" style={{ width: "8%" }}>
              Quantité
            </th>
            <th className="ant-table-cell" style={{ width: "8%" }}>
              Empalcement
            </th>
            <th className="ant-table-cell" style={{ width: "8%" }}>
              Ancien Empalcement
            </th>
          </tr>
        </thead>
        <tbody className="ant-table-tbody">
          {null != bon &&
            bon.items &&
            bon.items.map((item) => (
              <tr style={{ backgroundColor: item.id === selected ? "#72cbf2" : "", cursor: "pointer" }} onClick={() => setSelected(item.id)}>
                <td className="ant-table-cell" style={{ width: "8%" }}>
                  {item.article.s__reference}
                </td>
                <td className="ant-table-cell">{item.article.s__secondTitle}</td>
                <td className="ant-table-cell" style={{ width: "10%" }}>
                  {item.d__quantity}
                </td>
                <td className="ant-table-cell" style={{ width: "30%" }}>
                  <Input
                    style={{ flex: "auto" }}
                    onFocus={() => setSelected(item.id)}
                    value={articleEmplacement.length > 0 ? articleEmplacement.filter((el) => el.articleId == item.article.id)[0].emplacement : ""}
                    onChange={(e) => onChangeEmplacement(item.article.id, e)}
                  />

                  {/*<Select
                    showSearch
                    style={{ width: "100%" }}
                    onChange={(e) => onChangeEmplacement(item.article.id, e)}
                    defaultValue={item.article.emplacement && item.article.emplacement.title}
                    dropdownRender={(menu) => (
                      <div>
                        <Divider style={{ margin: "4px 0" }} />
                        <div
                          style={{
                            display: "flex",
                            flexWrap: "nowrap",
                            padding: 8,
                          }}
                        >
                          <Input style={{ flex: "auto" }} value={emplacementToAdd} onChange={(e) => setEmplacementToAdd(e.target.value)} />
                          <a
                            style={{
                              flex: "none",
                              padding: "8px",
                              display: "block",
                              cursor: "pointer",
                            }}
                            onClick={(e) => emplacementToAdd != "" && addItemEmplacement()}
                          >
                            <PlusOutlined /> Nouveau
                          </a>
                        </div>
                        {menu}
                      </div>
                    )}
                  >
                    {emplacements.map((emplacement, i) => (
                      <Select.Option value={emplacement.title} key={i}>
                        {emplacement.title}
                      </Select.Option>
                    ))}
                    </Select>*/}
                </td>
                <td className="ant-table-cell" style={{ width: "30%" }}>
                  {item.article.emplacement ? item.article.emplacement.title : ""}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Modal>
  );
};
export default ChangeEmplacement;
